import React,{useState} from "react"
import {Link} from "gatsby";
//import {trackCustomEvent} from "gatsby-plugin-google-analytics";
import axios from "axios";
import Cookies from 'universal-cookie'

const SignupNotification = ({showParent}) => {

    const [error,setError] = useState(false);
    const [submitted,setSubmitted] = useState(false)
    const [waiting,setWaiting] = useState(false)
    const [phone,setPhone] = useState("")
    const [showForm,setShowForm] = useState(false)

    const checkPhoneInput = (inputEvent) => {

        setError(false)

        if(((phone.length == 0 && inputEvent.key.match(/[49]/)) || (phone.length > 0 && inputEvent.key.match(/[0-9]/)))){
            const temp =  phone + inputEvent.key
            if(temp.match(/^[0-9]{0,8}$/)){
                setPhone(phone + inputEvent.key);
            }
        }
        else if(inputEvent.key == "Backspace"){
            setPhone(phone.substring(0,phone.length-1));
        }
        else{
            inputEvent.target.selectionStart = inputEvent.target.selectionEnd = 10000;
        }
    }

    const submitPhoneNotification = async() => {
        if(showForm && !submitted){
            setError(false)

            if(phone.match(/[49][0-9]{7}/)){
                setWaiting(true)
                let postData =  new FormData();
                postData.append("action","subscribe_sms");
                postData.append("num",phone);

                try{
                    await axios(
                        {
                            'method': "post",
                            'url': "https://app.gjerrigknark.com/data/gratis.no/notifications.php",
                            'data': postData,
                            'headers': { "Content-Type": "multipart/form-data" },
                        }

                    );



                    const date = new Date();
                    date.setTime(date.getTime() + (365*24*60*60*1000));

                    try{
                        const cookies = new Cookies();
                        cookies.set('signup', true, { path: '/', expires: date});
                        //console.log("Cookie? => ")
                    }
                    catch (e){
                        console.log(e)
                    }
                    setWaiting(false);
                    setSubmitted(true);
                    //console.log("Submitted?")
                }
                catch (e){
                    setWaiting(false);
                    //console.log("Failed");
                }
            }
            else{
                setError(true)
            }

        }
        else if(submitted){
            //setShowForm(false)
            showParent(false)
        }
    }

    return(
        <>
            <Link
                aria-label="Få varslinger"
                className={"button button-icon button-icon-right button-black button-lg" + (showForm && " open")}
                style={{flexDirection:'column'}}
                to="/#"
                onClick={e => {
                    if(!showForm){
                        setShowForm(true);

                        // https://www.gatsbyjs.org/packages/gatsby-plugin-google-analytics/
                        // To stop the page reloading
                        e.preventDefault()
                        // Lets track that custom click
                        /*trackCustomEvent({
                            // string - required - The object that was interacted with (e.g.video)
                            category: "Notifications",
                            // string - required - Type of interaction (e.g. 'play')
                            action: "Click",
                            // string - optional - Useful for categorizing events (e.g. 'Spring Campaign')
                            label: "Get notifications button - front page",
                            // number - optional - Numeric value associated with the event. (e.g. A product ID)
                            value: 1
                        })*/
                        //... Other logic here
                    }
                    else{
                        e.preventDefault();
                    }
                }}
            >{showForm && !submitted &&
                <div style={{float:'left',clear:'both'}}>
                    <input className={"notificationInput" + ((error)?" error":"")} value={phone} onKeyDown={checkPhoneInput} style={{textAlign: ((phone.length > 0)?'center':'left'),marginBottom:'10px', zIndex:1001, padding:'8px',paddingLeft:'15px',borderRadius:'8px'}} placeholder={"Ditt mobilnummer"}/>
                </div>
            }
            <div onClick={submitPhoneNotification} className={(showForm?" submitNotification":"")} style={{display:'inline-flex', alignContent:'center', alignItems:'center', justifyContent:'center', justifyItems:'center'}}>
                {!waiting && !submitted &&
                    <><span style={{whiteSpacing:'nowrap'}}
                      className={"icon novi-icon fa fa-chevron-right"}/>{showForm && <>Bestill varsling</>}{!showForm && <>Trykk her</>}</>}
                {waiting && <span>Sender...</span>}
                {submitted && <span>Påmelding vellykket!</span>}
            </div>
            </Link>
        </>
    )
}

export default SignupNotification