import React,{useState,useEffect} from "react"
import { Link, graphql} from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
//import { trackCustomEvent } from 'gatsby-plugin-google-analytics'
import { StaticImage } from "gatsby-plugin-image"
import UtilityStoryitem from '../components/utilityStoryitem.js'
import SignupNotification from "../components/signupNotification";
import Cookies from "universal-cookie";

// Conditional syntax in JSX
// https://stackoverflow.com/questions/53508119/what-is-the-syntax-for-a-simple-if-conditional-in-gatsby-js


export const query= graphql`
query {

    valOffer: allSanityOffer(limit:5, filter:{isDeleted: {ne: true}}, sort: {fields: value, order: DESC}){
        edges {
          node {
            gkid {
              current
            }
            slug {
              current
            }
            title
            description {
              list
            }
            value
            image {
              ...Image
            }
          }
        }
      }

    popOffer: allSanityOffer(limit:6, filter:{isDeleted: {ne: true}}, sort: {fields: popularity_rank, order: DESC}){
        edges {
          node {
            gkid {
              current
            }
            slug {
              current
            }
            title
            description {
              list
            }
            value
            image {
              ...Image
            }
          }
        }
      }
    
     newOffer: allSanityOffer(limit:7, filter:{isDeleted: {ne: true}, published: {ne: null}}, sort: {fields: published, order: DESC}){
        edges {
          node {
            gkid {
              current
            }
            slug {
              current
            }
            title
            description {
              list
            }
            value
            image {
              ...Image
            }
          }
        }
      }
    
    bgImage:  file(relativePath: { eq: "home-01-1920x570.jpg" }) {
      childImageSharp {
        fixed(width: 1920, height: 570) {
          src
        }
      }
    }
    
    h1Image:  file(relativePath: { eq: "banner-arrow-184x147.png" }) {
      childImageSharp {
        fixed(width: 184, height: 147) {
          src
        }
      }
    }

    img1: file(relativePath: { eq: "page1_pic2-270x271.jpg" }) {
      childImageSharp {
        fixed(width: 270, height: 271) {
          src
        }
      }
    }
}
`

const IndexPage = ({data}) => {

  //console.log(window.location.pathname)
  const [showSignup,setShowSignup] = useState(true)

  useEffect(() => {
        const cookies = new Cookies();

        const signedUp = cookies.get('signup');
        //console.log("Signed up:")
        //console.log(signedUp)

        if(signedUp && signedUp != undefined){
            setShowSignup(false);
        }
      }
      ,[]);




  return (
    <Layout>
      <SEO
        title="Gratis ting og velkomstgaver | Gratis.no"
        description={"Nettsted med oversikt over gratis ting og tjenester som du kan bestille."}
        image={data.bgImage.childImageSharp.fixed}
      />

      <div>
        {/* Få varsel */}

        {showSignup &&
            <section
                className="section novi-bg novi-bg-img section-xl section-banner-classic bg-primary text-center text-lg-left"
                style={{backgroundImage: 'url(' + data.bgImage.childImageSharp.fixed.src + ')'}}>
              <div className="container">
                <div className="row">
                  <div className="col-xl-7 col-lg-9">
                    <div className="banner-classic">
                      <h1>Få varsel om <span className="d-block font-weight-bold">nye gratisting!</span>
                        <img className="banner-classic-figure" src={data.h1Image.childImageSharp.fixed.src} alt=""
                             width={184} height={147}/>
                        {/*<StaticImage className="banner-classic-figure" src={"../images/banner-arrow-184x147.png"} alt={"Pil"} width={184} height={147}/>*/}
                      </h1>
                      <SignupNotification showParent={setShowSignup}/>
                    </div>
                  </div>
                </div>
              </div>
            </section>
        }


        {/* Nye gratisting */}
        <section className={"section novi-bg novi-bg-img section-lg bg-white" + (!showSignup?" section-top-shadow":"")}>
          <div className="container">
            <h2 style={{marginBottom:'80px'}} className="text-center text-sm-left">Nye <span className="h2-style">Gratisting</span></h2>
            <div className="row row-50">

              {data.newOffer.edges.map(({ node: offer }) => (
                <div key={offer.gkid.current} className="col-lg-3 col-sm-6">
                  <UtilityStoryitem slug={offer.slug.current} headline={offer.title} image={offer.image} value={offer.value}/>
                </div>
              ))}

            </div>
            <div className="mt-xl-40 mt-50 text-center text-sm-right">
              <Link
                onClick={e => {
                  // To stop the page reloading
                  //e.preventDefault()
                  // Lets track that custom click
                  /*trackCustomEvent({
                    // string - required - The object that was interacted with (e.g.video)
                    category: "Navigation Button",
                    // string - required - Type of interaction (e.g. 'play')
                    action: "Click",
                    // string - optional - Useful for categorizing events (e.g. 'Spring Campaign')
                    label: "Show new",
                    // number - optional - Numeric value associated with the event. (e.g. A product ID)
                    value: 1
                  })*/
                  //... Other logic here
                }}
                className="button button-icon button-icon-right button-black" to="/nye-gratisting/"><span
                className="icon novi-icon fa fa-chevron-right"/>Vis alle nye</Link></div>
          </div>
        </section>

        {/* Populære gratisting */}
        <section className="section novi-bg novi-bg-img section-lg bg-white  section-top-shadow">
          <div className="container">
            <h2 style={{marginBottom:'80px'}} className="text-center text-sm-left">Populære <span className="h2-style">Gratisting</span></h2>
            <div className="row row-50">

              {data.popOffer.edges.map(({ node: offer }) => (
                <div key={offer.gkid.current} className="col-lg-3 col-sm-6">
                  <UtilityStoryitem slug={offer.slug.current} headline={offer.title} image={offer.image} value={offer.value}/>
                </div>
              ))}

            </div>
            <div className="mt-xl-40 mt-50 text-center text-sm-right">
              <Link
                onClick={e => {
                  // To stop the page reloading
                  //e.preventDefault()
                  // Lets track that custom click
                  /*trackCustomEvent({
                    // string - required - The object that was interacted with (e.g.video)
                    category: "Navigation Button",
                    // string - required - Type of interaction (e.g. 'play')
                    action: "Click",
                    // string - optional - Useful for categorizing events (e.g. 'Spring Campaign')
                    label: "Show popular",
                    // number - optional - Numeric value associated with the event. (e.g. A product ID)
                    value: 1
                  })*/
                  //... Other logic here
                }}
              className="button button-icon button-icon-right button-black" to="/populare-gratisting/"><span
              className="icon novi-icon fa fa-chevron-right"/>Vis alle populære</Link></div>
          </div>
        </section>

        {/* Verdifulle gratisting */}
        <section className="section novi-bg novi-bg-img section-lg bg-white section-top-shadow">
          <div className="container">
            <h2 style={{marginBottom:'80px'}} className="text-center text-sm-left">Verdifulle <span className="h2-style">Gratisting</span></h2>
            <div className="row row-50">

              {data.valOffer.edges.map(({ node: offer }) => (
                <div key={offer.gkid.current} className="col-lg-3 col-sm-6">
                  <UtilityStoryitem slug={offer.slug.current} headline={offer.title} image={offer.image} value={offer.value}/>
                </div>
              ))}

            </div>
            <div className="mt-xl-40 mt-50 text-center text-sm-right">
              <Link
                onClick={e => {
                  // To stop the page reloading
                  //e.preventDefault()
                  // Lets track that custom click
                  /*trackCustomEvent({
                    // string - required - The object that was interacted with (e.g.video)
                    category: "Navigation Button",
                    // string - required - Type of interaction (e.g. 'play')
                    action: "Click",
                    // string - optional - Useful for categorizing events (e.g. 'Spring Campaign')
                    label: "Show valuable",
                    // number - optional - Numeric value associated with the event. (e.g. A product ID)
                    value: 1
                  })*/
                  //... Other logic here
                }}
              className="button button-icon button-icon-right button-black" to="/gratisting-med-hoy-verdi/"><span
              className="icon novi-icon fa fa-chevron-right"/>Vis alle verdifulle</Link></div>
          </div>
        </section>


      </div>
    </Layout>
  )
}

export default IndexPage


